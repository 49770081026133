<template>
  <div class="card card-custom card-sticky">
    <notifications group="notification" position="top right" />
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          <span class="svg-icon svg-icon-primary svg-icon-2x">
            <inline-svg src="media/svg/icons/Map/Position.svg" />
          </span>
        </h3>
      </div>
      <div class="card-toolbar">
        <router-link
          to="/tags/list"
          class="btn btn-light-primary font-weight-bolder mr-2"
        >
          <i class="ki ki-long-arrow-back icon-sm"></i>
          All Tags
        </router-link>
        <div class="js-submit-options btn-group">
          <button
            @click="postEntity"
            type="button"
            class="btn btn-primary font-weight-bolder"
          >
            <i class="ki ki-check icon-sm"></i>
            Add Tag
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-xl-2"></div>
        <div class="col-xl-8">
          <div class="my-5">
            <h3 class="text-dark font-weight-bold mb-10">Info:</h3>
            <div class="form-group row">
              <label class="col-3">Name:</label>
              <div class="col-9">
                <input
                  v-model="tag.name"
                  class="form-control form-control-solid"
                  type="text"
                  placeholder="Enter tag name"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'TagsNew',
  data() {
    return {
      tag: {
        name: null,
        company: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Add new Tag', route: '' }])
    this.tag.company = this.currentUserCompany
  },
  methods: {
    postEntity() {
      ApiService.post('/tags', this.tag)
        .then(() => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Tag neuspješno dodan.',
          })

          setTimeout(() => {
            this.$router.push({ name: 'tags-list' })
          }, 500)
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss"></style>
